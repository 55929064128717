var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.enableSearch)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"input",attrs:{"type":"text","placeholder":"Search plan"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}):_vm._e(),_vm._v(" "),(_vm.allElevations && _vm.allElevations.length > 0)?_c('div',{ref:"elevationsContainer",staticClass:"h-70-screen overflow-y-auto grid grid-cols-1 gap-4 sm:grid-cols-4",attrs:{"tabindex":"0"},on:{"focusout":_vm.handleBlur}},_vm._l((_vm.allElevations),function(elevation){return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyOption),expression:"tippyOption"}],key:elevation.id,class:[
        {
          'cursor-pointer': !elevation.disabled && _vm.isVariance,
          'cursor-not-allowed':
            (elevation.disabled && !_vm.isVariance) || _vm.disabled,
        } ],attrs:{"content":"If you would like to adjust the floor plan and elevation for this submittal, you must cancel the associated sale first"},on:{"click":function($event){;(elevation.disabled && !_vm.isVariance) || _vm.disabled
          ? function () {}
          : _vm.handleClick(elevation.id)}}},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"card bg-white",class:{
            active: _vm.selected && _vm.selected === elevation.id,
            'bg-gray-300': _vm.disabled,
            'hover:bg-blue-100 hover:border-blue-500 hover:shadow-lg':
              !_vm.disabled,
          }},[_c('div',{staticClass:"aspect-w-3 aspect-h-2"},[_c('img',{staticClass:"object-cover rounded-none",attrs:{"onerror":"this.src='https://thexo-cdn.nyc3.digitaloceanspaces.com/xo-placeholder.png;'","src":elevation.photo
                  ? elevation.photo
                  : 'https://thexo-cdn.nyc3.digitaloceanspaces.com/xo-placeholder.png',"alt":elevation.id}}),_vm._v(" "),(elevation.disabled && !_vm.isVariance)?_c('div',{staticClass:"text-xs flex items-end"},[_c('span',{staticClass:"bg-red-500 p-1 text-white"},[_vm._v("\n                "+_vm._s(elevation.disabled_reason))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"p-6"},[_c('p',{staticClass:"text-md text truncate"},[_vm._v("\n              "+_vm._s(elevation.plan ? elevation.plan.name : '-')+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"text-sm text-gray-700"},[_vm._v("\n              "+_vm._s(elevation.name ? elevation.name : '-')+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"flex justify-between p-3 border-t border-gray-300 bg-blueGray-200 text-gray-700 text-sm"},[_c('div',{staticClass:"flex flex-col"},[_c('span',[_vm._v(" Primary: ")]),_vm._v(" "),_c('span',{staticClass:"text ml-1 capitalize"},[_vm._v("\n                "+_vm._s(elevation.primary_material_category
                    ? elevation.primary_material_category
                    : '-')+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"flex flex-col"},[_c('span',[_vm._v("\n                "+_vm._s(_vm.isSpecialFeatureEnabled && elevation.special_feature
                    ? 'Special Feature'
                    : 'Secondary')+":\n              ")]),_vm._v(" "),_c('span',{staticClass:"text mr-1 capitalize"},[_vm._v("\n                "+_vm._s(_vm.secondaryMaterialLabel(elevation))+"\n              ")])])])])])])}),0):_c('div',[_c('div',{staticClass:"border border-yellor-400 bg-yellow-100 rounded-md p-8"},[_c('fa',{attrs:{"icon":['axis', 'warning'],"size":"lg"}}),_vm._v(" "),_c('span',{staticClass:"font-light"},[_vm._v("\n        No elevations available, try selecting another style\n      ")])],1)]),_vm._v(" "),(_vm.areElevationsLoading)?_c('BottomLoader'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }