import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { LoginForm } from '~/models/forms'
import BaseState from '~/models/state/baseState'
import { userStore, constantsStore } from '~/store'
import { $axios } from '~/utils/api'
import { removeCookies } from '~/utils/cookies'
import routes from '~/constants/routerPath.js'

@Module({
  name: 'auth',
  stateFactory: true,
  namespaced: true,
})
export default class Auth extends VuexModule implements BaseState {
  token: string = null
  refreshToken: string = null
  status: string = null
  redirect_uri: string = ''

  get isAuthenticated(): boolean {
    return !!this.token
  }

  get redirectUrl() {
    return this.redirect_uri
  }

  get accessToken(): string {
    return this.token
  }

  @Action({ rawError: true })
  async login(loginForm: LoginForm): Promise<any> {
    this.authRequest()
    const response = await $axios.$post('/login/', {
      email: loginForm.email,
      password: loginForm.password,
    })
    this.authSuccess(response)
    await userStore.getUser()
    constantsStore.initConstants()
    return response
  }

  @Action({ rawError: true })
  async logout() {
    try {
      const response = await $axios.$post('/logout/', {
        refresh: this.refreshToken,
      })

      removeCookies()
      this.setRedirectUrl('/lots')
      return response
    } catch (error) {
      console.log(error)
    }
  }

  @Action({ rawError: true })
  async refresh(): Promise<any> {
    this.authRequest()
    try {
      const response = await $axios.$post('/login/refresh/', {
        refresh: this.refreshToken,
      })
      this.authSuccess(response)
      return response
    } catch (err: any) {
      // @ts-ignore
      $nuxt._router.push(routes.login)
      return err
    }
  }

  @Mutation
  authRequest() {
    this.status = 'loading'
  }

  @Mutation
  authSuccess(resp: any) {
    this.status = 'success'
    this.token = resp.access
    this.refreshToken = resp.refresh
  }

  @Mutation
  authError() {
    this.status = 'error'
  }

  @Mutation
  clear() {
    this.refreshToken = null
    this.token = null
    this.status = null
  }

  @Mutation
  setRedirectUrl(url: string) {
    this.redirect_uri = url
  }

  @Mutation
  refreshRequest() {
    this.status = 'loading'
  }

  @Mutation
  refreshSuccess(resp: any) {
    this.status = 'success'
    this.token = resp.access
    this.refreshToken = resp.refresh
  }

  @Mutation
  refreshError() {
    this.status = 'error'
  }
}
