


















































































































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import TitleInput from './TitleInput.vue'
import { Project, Elevation } from '~/models'
import { setScrollOnBottomEvent, removeScrollListener } from '~/utils/scroll'
import BottomLoader from '~/components/shared/BottomLoader.vue'
import debounce from '~/utils/debounce'

@Component({
  components: {
    TitleInput,
    BottomLoader,
  },
  computed: {
    ...mapGetters('user', {
      project: 'project',
      isSpecialFeatureEnabled: 'isSpecialFeatureEnabled',
    }),
    ...mapGetters('ui', {
      areElevationsLoading: 'areElevationsLoading',
    }),
  },
})
export default class ElevationsInput extends Vue {
  selected: number
  isSpecialFeatureEnabled: boolean

  @Prop({ default: 0 })
  value: number

  @Prop({ default: (): any[] => [] })
  elevations: any[]

  @Prop()
  titleSize: string

  @Prop()
  subTitleSize: string

  @Prop({ default: false })
  required: boolean

  @Prop({ default: false })
  disabled: boolean

  @Prop({ default: false })
  isVariance: boolean

  @Prop({ default: false })
  enableSearch: boolean

  project: Project
  areElevationsLoading: boolean
  elevationScrollListener: any = null
  search: string = ''

  searchDebounce: Function = null
  allElevations: any[] = []

  constructor() {
    super()
    this.selected = this.value
  }

  mounted() {
    this.searchDebounce = debounce(this.onSearch, 500)
  }

  get tippyOption() {
    return {
      trigger: this.disabled ? 'mouseenter' : '',
    }
  }

  secondaryMaterialLabel(elevation: Elevation): string {
    const category = elevation.secondary_material_category

    if (!this.isSpecialFeatureEnabled) {
      return category || '-'
    }

    return elevation.special_feature || category || '-'
  }

  @Watch('value')
  onValueChanged() {
    this.selected = this.value
  }

  @Emit('input')
  handleClick(elevationId: number) {
    this.selected = elevationId
    return this.selected
  }

  @Emit('loadMoreItems')
  loadMoreElevations(): void {}

  updated() {
    if (this.elevationScrollListener === null) {
      this.elevationScrollListener = setScrollOnBottomEvent(
        this.loadMoreElevations,
        this.$refs.elevationsContainer
      )
    }
    this.onValueChanged()
  }

  beforeDestroy() {
    removeScrollListener(
      this.$refs.elevationsContainer,
      this.elevationScrollListener
    )
  }

  @Emit('validateField')
  handleBlur(): any {}

  @Emit('onSearchPlan')
  onSearch(search: string) {
    return search
  }

  @Watch('search')
  searching(search: string) {
    this.searchDebounce(search)
  }

  @Watch('elevations')
  removeRepeatedElevationInEdition() {
    this.allElevations = this.elevations.filter(
      (elevation, index, self) =>
        index === self.findIndex((e) => e.id === elevation.id)
    )
  }
}
